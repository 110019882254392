export const SocialMediaLinks = [
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/mohd-rizwan1/',
      imgSrc: '/Post.png', 
      color : "#008FE8" 

    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/code.withrizwan/',
      // imgSrc: '/Instagram.png', 
      imgSrc: '/Insta.png', 

      color : "#E03466" 

    },
    {
      name: 'Twitter',
      url: 'https://x.com/codewithrizwan1',
      imgSrc: '/twitter.png', 
      color:"#007ADC"
    },
    {
      name: 'GitHub',
      url: 'https://github.com/rizwan0713',
      imgSrc: '/Github.png', 
      color:"#252525"
    },


  ];
  